import { getomorder } from '@/api/order/om/order'

const openmarketorder = {
  state: {
    isManage: false,
    pageType: '',
    categoryId: null,
    orderId: 0,
    orderBean: {},
    beginUpdateMap: new Date(),
    mapType: '',
    stations: [],
    searchCondition: {},
    beginUpdatePurchase: new Date(),
    defaultProduct: null,
    bottomProduct: null,
    showAssets: [],
    checkSchedules: [],
    purchaseDuration: 0,
    leftReload: new Date(),
    detailReload: new Date(),

    clickGetMapStationId: null,
    clickGeoMapPurchase: new Date()
  },
  mutations: {
    set_openmarketorder_isManage (state, data) {
      state.isManage = data
    },
    set_openmarketorder_pageType (state, data) {
      state.pageType = data
    },
    set_openmarketorder_categoryId (state, data) {
      state.categoryId = data
    },
    set_openmarketorder_bean (state, data) {
      state.orderBean = data
    },
    set_openmarketorder_ID (state, data) {
      state.orderId = data
    },
    set_openmarketorder_update_map (state, data) {
      state.beginUpdateMap = data
    },
    set_openmarketorder_map_type (state, data) {
      state.mapType = data
    },
    set_openmarketorder_stations (state, data) {
      state.stations = data
    },
    set_openmarketorder_search_condition (state, data) {
      state.searchCondition = data
    },
    set_openmarketorder_update_purchase (state, data) {
      state.beginUpdatePurchase = data
    },
    set_openmarketorder_defaultProduct (state, data) {
      state.defaultProduct = data
    },
    set_openmarketorder_bottom_product (state, data) {
      state.bottomProduct = data
    },
    set_openmarketorder_show_assets (state, data) {
      state.showAssets = data
    },
    set_openmarketorder_check_schedule (state, data) {
      state.checkSchedules = data
    },
    set_openmarketorder_purchase_duration (state, data) {
      state.purchaseDuration = data
    },
    set_openmarketorder_update_left (state, data) {
      state.leftReload = data
    },
    set_openmarketorder_detail_reload (state, data) {
      state.detailReload = data
    },
    set_openmarketorder_update_getMapPurchase (state, data) {
      state.clickGeoMapPurchase = data
    },
    set_openmarketorder_update_getMapStationId (state, data) {
      state.clickGetMapStationId = data
    }
  },
  getters: {

  },
  actions: {
    // 获取订单基础信息
    getOMOrderBaseInfo ({ state, commit }, query) {
      return new Promise((resolve) => {
        getomorder(query).then(res => {
          commit('set_openmarketorder_bean', res)
          // commit('set_openmarketorder_ID', res.id)
          resolve(res)
        })
      })
    }
  }
}

export default openmarketorder
