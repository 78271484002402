<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <div class="platform-buttom-list">
          <div v-for="tag in tagList" :key="tag.key" @click="handleChangeTag(tag.key)"
           :class="tagType===tag.key? 'bottom-button-active':'bottom-button'">
           {{tag.value}}
          </div>
        </div>

        <component v-if="curBottomComponent === 'bottom_OrderList'" :is="'bottom_OrderList'"></component>
        <component v-show="curBottomComponent !== 'bottom_OrderList'" :is="curBottomComponent"></component>
      </div>
      <div slot="bg_map">
        <component :is="curMapComponent"></component>
      </div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import openmarketorderStoreModule from '@/store/modules/openmarketorder'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    centerComponent: () => import('@/components/openmarketOrder/Center'),
    // 底部组件
    // bottom_OrderList: () => import('@/components/order/bottom/List_Saler'),
    bottom_OrderList: () => import('@/components/openmarketOrder/List'),
    bottom_OrderDetail: () => import('@/components/openmarketOrder/Detail'),
    // 右侧组件
    right_OrderDetail: () => import('@/components/openmarketOrder/right/Detail')
  },
  data () {
    return ({
      tagType: 0,
      tagList: []
    })
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('openmarketorder')) {
      this.$store.registerModule('openmarketorder', openmarketorderStoreModule)
    }
  },
  mounted () {
    this.setRightComponent('')
    this.setShowRightBlock(false)

    this.setBottomComponent('bottom_OrderList')
    this.setBottomHeight('90%')
    this.setShowLeftBlock(false)
    this.tagList = [
      { key: 1, value: '订单列表' }
    ]
    this.tagType = 1
  },
  methods: {
    clearPage () {
      // 清空地图标注
      this.$store.commit('set_openmarketorder_stations', [])
      this.$store.commit('set_openmarketorder_update_map', new Date())
      this.$store.commit('set_openmarketorder_show_assets', [])
      this.$store.commit('set_openmarketorder_defaultProduct', undefined)
    },
    handleChangeTag (val) {
      if (val === 1) {
        this.tagType = 1
        this.$store.commit('set_openmarketorder_pageType', 'list')
        this.setBottomComponent('bottom_OrderList')

        if (this.curBottomHeight === '5px') {
          this.setBottomHeight('300px')
        }
      } else {
        this.$store.commit('set_openmarketorder_pageType', val === 2 ? 'edit' : 'detail')
        // this.loadPageComponent(val === 2 ? 'edit' : 'detail')
      }
    },
    loadPageComponent (type) {
      if (type === 'detail') {
        this.setShowRightBlock(true)
        this.setRightComponent('right_OrderDetail')
        this.setShowLeftBlock(false)
        this.setLeftComponent('')
        this.setBottomHeight('90%')
        this.setBottomComponent('bottom_OrderDetail')
        this.setMapComponent('centerComponent')

        this.tagList = [
          { key: 1, value: '我的采购清单' },
          { key: 3, value: '订单详情' }
        ]
        this.tagType = 3
      } else {
        this.setShowRightBlock(false)
        this.setRightComponent('')
      }
    }
  },
  computed: {
    pageType () {
      return this.$store.state.openmarketorder ? this.$store.state.openmarketorder.pageType : ''
    },
    orderId () {
      return this.$store.state.openmarketorder ? this.$store.state.openmarketorder.orderId : ''
    }
  },
  watch: {
    pageType (val) {
      if (val !== 'list') {
        this.clearPage()
        this.loadPageComponent(val)
      }
    },
    orderId (val) {
      this.clearPage()
    }
  },
  destroyed () {
    this.$store.commit('set_openmarketorder_pageType', '')
    // 卸载动态store模块
    this.$store.unregisterModule('openmarketorder')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setBottomComponent('')
    this.setMapComponent('')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
