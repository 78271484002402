import request from '@/utils/requestV2'
const qs = require('qs')

// 获取订单状态
export function getorderstatus () {
  return request({
    url: '/ooh-order/om/v1/order/getorderstatus',
    method: 'get'
  })
}
// 获取账单列表
export function getbillpage (data) {
  return request({
    url: '/ooh-order/om/v1/order/getbillpage',
    method: 'get',
    params: data
  })
}
// 获取公开市场账单汇总统计信息
export function getbillstatistic (data) {
  return request({
    url: '/ooh-order/om/v1/order/getbillstatistic',
    method: 'get',
    params: data
  })
}

// 分页获取订单列表（公开市场）
export function getOMorderpage (data) {
  return request({
    url: '/ooh-order/om/v1/order/getorderpage',
    method: 'get',
    params: data
  })
}
// 设置订单为未上刊
export function setordernotinstall (data) {
  return request({
    url: '/ooh-order/om/v1/order/setordernotinstall',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 设置订单为已退款
export function finishrefund (data) {
  return request({
    url: '/ooh-order/om/v1/order/finishrefund',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取订单信息
export function getomorder (data) {
  return request({
    url: '/ooh-order/om/v1/order/getorder',
    method: 'get',
    params: data
  })
}

// 修改订单所属销售
export function modifyOmOrderSeller (data) {
  return request({
    url: '/ooh-order/om/v1/order/modifyOmOrderSeller',
    method: 'post',
    data: qs.stringify(data)
  })
}
